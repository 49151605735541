
import Validation from "@/components/Validation.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import FscDatepicker from "@/components/FscDatepicker.vue";
import GuestLayout from "@/layout/GuestLayout.vue";
import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import SchoolLogo from "@/components/Avatars/SchoolLogo.vue";
import { IBasicNamedDto } from "@/interfaces/IBasicNamedDto";
import { ITheoryCourse } from "@/interfaces/ITheoryCourse";

export default defineComponent({
  components: {
    FscMultiselect,
    GuestLayout,
    FscDatepicker,
    Validation,
    multiselect: FscMultiselect,
    SchoolLogo,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const $toasted = getCurrentInstance()?.appContext.config.globalProperties.$toasted;
    const guestLayout = ref<any>();
    const loadingCourses = ref<boolean>();

    onMounted(() => {
      getLicenceClasses();
    });

    const selectedLicenseClass = computed({
      get: () => store.getters["public-info/getSelectedLicenseClass"],
      set: (value) => store.commit("public-info/SET_SELECTED_LICENCE_CLASSES", value),
    });

    const selectedTheoryCourse = computed({
      get: () => store.getters["public-info/getSelectedTheoryCourse"],
      set: (value) => store.commit("public-info/SET_SELECTED_THEORY_COURSE", value),
    });

    const drivingSchool = computed(() => store.getters["public-info/getDrivingSchool"]);
    const licenceClasses = computed(() => store.getters["public-info/getLicenceClasses"]);
    const theoryCourses = computed<Array<ITheoryCourse>>(() => store.getters["public-info/getTheoryCourses"] || []);

    const getLicenceClasses = () => {
      store.dispatch("public-info/getLicenceClasses", drivingSchoolId.value);
    };
    const getTheoryCourses = async (opt: { drivingSchoolId: number | undefined; licenseClass: string; branchId: null }) => {
      await store.dispatch("public-info/getTheoryCourses", opt);
    };

    const drivingSchoolId = computed(() => {
      return route.params?.drivingSchool || 0;
    });

    const drivingSchoolName = computed(() => {
      return drivingSchool.value?.name || route.query?.drivingSchoolName || "";
    });

    const onSelectLicenseClass = async (option: IBasicNamedDto | null) => {
      loadingCourses.value = true;
      if (!drivingSchoolId.value || !option) return;
      try {
        await getTheoryCourses({
          drivingSchoolId: Number(drivingSchoolId.value),
          licenseClass: option.name,
          branchId: null,
        });
      } catch (e) {
        console.log("Course Loading Failed...");
      }
      loadingCourses.value = false;
    };

    const onSelectTheoryCourser = (theoryCourse: any) => {
      if (!theoryCourse?.freeSlots) {
        return;
      }
      if (!selectedLicenseClass.value) {
        $toasted?.info("Select a license class!");
        return;
      }
      selectedTheoryCourse.value = theoryCourse;
      router.push({ name: "RegisterCourseOverviewForm" });
    };

    const onSkip = () => {
      selectedTheoryCourse.value = null;
      selectedLicenseClass.value = null;
      router.push({ name: "RegisterCourseOverviewForm" });
    };

    const onOpenMultiselect = async (ref: string, scrollY: number) => {
      let newScrollY = scrollY;
      await nextTick(() => {
        guestLayout.value.scrollToPoint?.(newScrollY);
        setTimeout(() => {
          guestLayout.value.setScrollY?.(false);
        }, 100);
      });
    };

    const onCloseMultiselect = async () => {
      guestLayout.value.setScrollY?.(true);
    };

    return {
      selectedLicenseClass,
      drivingSchoolId,
      drivingSchoolName,
      licenceClasses,
      theoryCourses,
      onSelectLicenseClass,
      onSelectTheoryCourser,
      selectedTheoryCourse,
      onSkip,
      onOpenMultiselect,
      onCloseMultiselect,
      guestLayout,
      loadingCourses,
    };
  },
});
